import styled from "@emotion/styled"


export const Tint = styled.div`
  width: 100%;
  height: 100vh;
  -webkit-backdrop-filter: blur(.15px);
  backdrop-filter: blur(.15px);
  background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.12) 18%);
  position: absolute;
  top: 0;
  left: 0;
  z-index:-1;
 `


export const ModalBgBox = styled.div`
  padding-top: 38.5px;
  max-width: 567px;
  background-color: rgba(0,0,0,0.85);
  width:92%
`